<template>
    <div
        class="overflow-y-auto mx-0 px-0 py-0 mt-3"
        :style="`height:${this.height}px`"
    >
        <v-row no-gutters class="mx-4">
            <v-col cols="12">
                <v-data-table
                    :headers="headers"
                    :items="filters"
                    class="elevation-0 clickable-row"
                    :mobile-breakpoint="0"
                    :loading="loading"
                    disable-pagination
                    hide-default-footer
                    @click:row="openCost"
                    :height="'75vh'"
                    fixed-header
                >
                    <template v-slot:top>
                        <div class="top">
                            <v-row
                                no-gutter
                                class="mt-0 mx-0 pt-4 pb-0 pb-4 align-center"
                                style="background-color: #eeeeee"
                            >
                                <h2 class="ml-4 mr-4">
                                    ESTIMATING PLAN
                                </h2>
                                <v-spacer />
                            </v-row>
                            <v-row
                                no-gutter
                                style="background-color: #eeeeee"
                                :class="
                                    $vuetify.breakpoint.xs
                                        ? 'd-flex justify-center py-4 mx-0'
                                        : 'd-flex justify-start py-4 mx-0'
                                "
                            >
                                <div class="d-flex">
                                    <template>
                                        <v-card
                                            class="pt-5 px-2 ma-2"
                                            min-width="250px"
                                            max-width="250px"
                                        >
                                            <v-card-text
                                                class="d-flex my-0 py-0 mx-0 px-0"
                                                width="100%"
                                            >
                                                <v-chip
                                                    class="mb-1 mt-0 mx-2 pt-0 white--text"
                                                    :color="insights[0].color"
                                                    label
                                                    style="height: 50px"
                                                >
                                                    <v-icon center>
                                                        {{ insights[0].icon }}
                                                    </v-icon>
                                                </v-chip>
                                                <div class="mb-1 mt-0 pt-0">
                                                    <div
                                                        class="d-flex mr-0 pr-0 text-capitalize"
                                                    >
                                                        <span
                                                            style="font-size: 20px; font-weight: bold;"
                                                            >{{
                                                                insights[0]
                                                                    .title
                                                            }}</span
                                                        >
                                                    </div>
                                                    <p
                                                        class="text-h6 black--text"
                                                    >
                                                        {{
                                                            filters.filter(
                                                                u =>
                                                                    u.quote &&
                                                                    u.quote
                                                                        .status ==
                                                                        'OPEN'
                                                            ).length
                                                        }}
                                                    </p>
                                                </div>
                                            </v-card-text>
                                        </v-card>
                                    </template>
                                </div>

                                <div class="d-flex">
                                    <template>
                                        <v-card
                                            class="pt-5 px-2 ma-2"
                                            min-width="250px"
                                            max-width="250px"
                                        >
                                            <v-card-text
                                                class="d-flex my-0 py-0 mx-0 px-0"
                                                width="100%"
                                            >
                                                <v-chip
                                                    class="mb-1 mt-0 mx-2 pt-0 white--text"
                                                    :color="insights[1].color"
                                                    label
                                                    style="height: 50px"
                                                >
                                                    <v-icon center>
                                                        {{ insights[1].icon }}
                                                    </v-icon>
                                                </v-chip>
                                                <div class="mb-1 mt-0 pt-0">
                                                    <div
                                                        class="d-flex mr-0 pr-0 text-capitalize"
                                                    >
                                                        <span
                                                            style="font-size: 20px; font-weight: bold;"
                                                            >{{
                                                                insights[1]
                                                                    .title
                                                            }}</span
                                                        >
                                                    </div>
                                                    <p
                                                        class="text-h6 black--text"
                                                    >
                                                        {{
                                                            filters.filter(
                                                                u =>
                                                                    u.engineering ==
                                                                        true &&
                                                                    u.quote &&
                                                                    u.quote
                                                                        .status !=
                                                                        'SENT'
                                                            )?.length
                                                        }}
                                                    </p>
                                                </div>
                                            </v-card-text>
                                        </v-card>
                                    </template>
                                </div>

                                <div class="d-flex">
                                    <template>
                                        <v-card
                                            class="pt-5 px-2 ma-2"
                                            min-width="250px"
                                            max-width="250px"
                                        >
                                            <v-card-text
                                                class="d-flex my-0 py-0 mx-0 px-0"
                                                width="100%"
                                            >
                                                <v-chip
                                                    class="mb-1 mt-0 mx-2 pt-0 white--text"
                                                    :color="insights[2].color"
                                                    label
                                                    style="height: 50px"
                                                >
                                                    <v-icon center>
                                                        {{ insights[2].icon }}
                                                    </v-icon>
                                                </v-chip>
                                                <div class="mb-1 mt-0 pt-0">
                                                    <div
                                                        class="d-flex mr-0 pr-0 text-capitalize"
                                                    >
                                                        <span
                                                            style="font-size: 20px; font-weight: bold;"
                                                            >{{
                                                                insights[2]
                                                                    .title
                                                            }}</span
                                                        >
                                                    </div>
                                                    <p
                                                        class="text-h6 black--text"
                                                    >
                                                        {{
                                                            filters.filter(
                                                                u =>
                                                                    u.quote &&
                                                                    u.quote
                                                                        .status ==
                                                                        'APPROVAL'
                                                            ).length
                                                        }}
                                                    </p>
                                                </div>
                                            </v-card-text>
                                        </v-card>
                                    </template>
                                </div>

                                <div class="d-flex">
                                    <template>
                                        <v-card
                                            :disabled="!loadFollowUp || loading"
                                            class="pt-5 px-2 ma-2"
                                            min-width="250px"
                                            max-width="250px"
                                            @click.stop="
                                                loadData('SENT'),
                                                    (loadFollowUp = !loadFollowUp)
                                            "
                                        >
                                            <v-card-text
                                                class="d-flex my-0 py-0 mx-0 px-0"
                                                width="100%"
                                            >
                                                <v-chip
                                                    class="mb-1 mt-0 mx-2 pt-0 white--text"
                                                    :color="insights[3].color"
                                                    label
                                                    style="height: 50px"
                                                >
                                                    <v-icon center>
                                                        {{ insights[3].icon }}
                                                    </v-icon>
                                                </v-chip>
                                                <div class="mb-1 mt-0 pt-0">
                                                    <div
                                                        class="d-flex mr-0 pr-0 text-capitalize"
                                                    >
                                                        <span
                                                            style="font-size: 20px; font-weight: bold;"
                                                            >{{
                                                                insights[3]
                                                                    .title
                                                            }}</span
                                                        >
                                                    </div>
                                                    <p
                                                        class="text-h6 black--text"
                                                    >
                                                        {{
                                                            filters.filter(
                                                                u =>
                                                                    u.quote &&
                                                                    u.quote
                                                                        .status ==
                                                                        'SENT'
                                                            ).length
                                                        }}
                                                    </p>
                                                </div>
                                            </v-card-text>
                                        </v-card>
                                    </template>
                                </div>
                            </v-row>
                        </div>
                    </template>
                    <!--HEADERS-->
                    <template v-slot:[`header.quote.name`]="{ header }">
                        <v-combobox
                            v-model="quotesToFilter"
                            :items="quotesArray"
                            :label="header.text"
                            flat
                            solo
                            background-color="#eeeeee"
                            multiple
                            item-text="name"
                            item-value="id"
                            hide-details
                        >
                            <template v-slot:selection="{ index }">
                                <div v-if="index === 0" class="mr-6">
                                    <span>Quotes</span>
                                </div>
                                <v-badge
                                    v-if="index === 0"
                                    :content="quotesToFilter.length"
                                    :value="quotes"
                                    color="primary"
                                    overlap
                                    class="mt-n2"
                                >
                                </v-badge>
                            </template>
                        </v-combobox>
                    </template>
                    <template v-slot:[`header.reference`]="{ header }">
                        <v-text-field
                            :label="header.text"
                            v-model="referenceToFilter"
                            dense
                            class="mt-4"
                        />
                    </template>
                    <template v-slot:[`header.category`]="{ header }">
                        <v-text-field
                            :label="header.text"
                            v-model="categoryToFilter"
                            dense
                            class="mt-4"
                        />
                    </template>
                    <template v-slot:[`header.quote.promiseDate`]="{ header }">
                        <v-text-field
                            :label="header.text"
                            v-model="promiseDateToFilter"
                            dense
                            class="mt-4"
                        />
                    </template>
                    <template v-slot:[`header.quote.clientId`]="{ header }">
                        <v-text-field
                            :label="header.text"
                            v-model="clientToFilter"
                            dense
                            class="mt-4"
                        />
                    </template>
                    <template v-slot:[`header.quote.userId`]="{ header }">
                        <v-combobox
                            v-model="usersToFilter"
                            :items="quotesUsers"
                            :label="header.text"
                            flat
                            solo
                            multiple
                            item-text="name"
                            item-value="id"
                            :return-object="false"
                            hide-details
                            background-color="#eeeeee"
                        >
                            <template v-slot:selection="{ index }">
                                <div v-if="index === 0" class="mr-6">
                                    <span>Users</span>
                                </div>
                                <v-badge
                                    v-if="index === 0"
                                    :content="usersToFilter.length"
                                    :value="quotes"
                                    color="primary"
                                    overlap
                                    class="mt-n2"
                                >
                                </v-badge>
                            </template>
                        </v-combobox>
                    </template>
                    <template v-slot:[`header.quote.status`]="{ header }">
                        <v-text-field
                            :label="header.text"
                            v-model="statusToFilter"
                            dense
                            class="mt-4"
                        />
                    </template>
                    <template v-slot:[`header.quote.requestDate`]="{ header }">
                        <v-text-field
                            :label="header.text"
                            v-model="requestDateToFilter"
                            dense
                            class="mt-4"
                        />
                    </template>

                    <template
                        v-slot:[`header.quote.invitationDate`]="{ header }"
                    >
                        <v-text-field
                            :label="header.text"
                            v-model="invitationDateToFilter"
                            dense
                            class="mt-4"
                        />
                    </template>
                    <template v-slot:[`header.engineeringDate`]="{ header }">
                        <v-text-field
                            :label="header.text"
                            v-model="engineeringDateToFilter"
                            dense
                            class="mt-4"
                        />
                    </template>

                    <!-- Items -->
                    <template v-slot:[`item.reference`]="{ item }">
                        <div dense class="pt-2">
                            {{ item.reference }}
                        </div>
                    </template>

                    <template v-slot:[`item.quote.clientId`]="{ item }">
                        <div class="my-4">
                            {{ getClientName(item.quote.clientId) }}
                        </div>
                    </template>

                    <template v-slot:[`item.quote.userId`]="{ item }">
                        <div class="my-0">
                            <div v-if="item.quote.estimatorName">
                                <v-tooltip right>
                                    <template v-slot:activator="{ on, attrs }">
                                        <p
                                            class="my-0 font-weight-medium"
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                            {{ item.quote.estimatorName }}
                                        </p>
                                    </template>
                                    <span>Account M.</span>
                                </v-tooltip>
                            </div>
                            <div v-if="item.quote.userName">
                                <v-tooltip right>
                                    <template v-slot:activator="{ on, attrs }">
                                        <p
                                            class="my-0 font-weight-medium"
                                            v-bind="attrs"
                                            v-on="on"
                                        >
                                            {{ item.quote.userName }}
                                        </p>
                                    </template>
                                    <span>Estimator</span>
                                </v-tooltip>
                            </div>
                        </div>
                    </template>

                    <template v-slot:[`item.quote.requestDate`]="{ item }">
                        <div dense class="pt-2">
                            {{
                                item.quote.requestDate &&
                                item.quote.requestDate._seconds
                                    ? formatDate(
                                          item.quote.requestDate._seconds
                                      )
                                    : ''
                            }}
                        </div>
                    </template>
                    <template v-slot:[`item.quote.promiseDate`]="{ item }">
                        <div dense class="pt-2">
                            {{
                                item.quote.promiseDate &&
                                item.quote.promiseDate._seconds
                                    ? formatDate(
                                          item.quote.promiseDate._seconds
                                      )
                                    : ''
                            }}
                        </div>
                    </template>
                    <template v-slot:[`item.quote.invitationDate`]="{ item }">
                        <div dense class="pt-2">
                            {{
                                item.quote.invitationDate &&
                                item.quote.invitationDate._seconds
                                    ? formatDate(
                                          item.quote.invitationDate._seconds
                                      )
                                    : ''
                            }}
                        </div>
                    </template>
                    <template v-slot:[`item.engineeringDate`]="{ item }">
                        <div dense class="pt-2">
                            {{
                                item.engineeringDate &&
                                item.engineeringDate._seconds
                                    ? formatDate(item.engineeringDate._seconds)
                                    : item.engineeringDate &&
                                      item.engineeringDate.seconds
                                    ? formatDate(item.engineeringDate.seconds)
                                    : item.engineeringDate
                                    ? item.engineeringDate
                                    : ''
                            }}
                        </div>
                    </template>
                    <template v-slot:[`item.quote.status`]="{ item }">
                        <div dense class="pt-2">
                            {{
                                item.engineering &&
                                item.engineering == true &&
                                item.quote &&
                                item.quote.status != 'SENT'
                                    ? 'Engineering'
                                    : item.quote.status == 'OPEN'
                                    ? 'Bidding'
                                    : item.quote.status == 'SENT'
                                    ? 'Follow Up'
                                    : item.quote.status == 'APPROVAL'
                                    ? 'Approval'
                                    : ''
                            }}
                        </div>
                    </template>

                    <template v-slot:[`item.actions`]="{ item }">
                        <div v-if="item">
                            <v-menu rounded offset-y>
                                <template v-slot:activator="{ attrs, on }">
                                    <v-btn
                                        icon
                                        v-bind="attrs"
                                        v-on="on"
                                        small
                                        depressed
                                        class="ml-2"
                                    >
                                        <v-icon>mdi-dots-vertical</v-icon>
                                    </v-btn>
                                </template>
                                <v-list dense>
                                    <v-list-item @click.stop="openQuote(item)">
                                        <v-list-item-icon>
                                            <v-icon class="mr-1"
                                                >mdi-eye</v-icon
                                            >
                                        </v-list-item-icon>
                                        <v-list-item-title>
                                            Open Quote View
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item
                                        v-if="item.quote.status == 'OPEN'"
                                        @click.stop="
                                            openEngineeringDialog(item)
                                        "
                                    >
                                        <v-list-item-icon>
                                            <v-icon
                                                v-if="!item.engineering"
                                                class="mr-1"
                                            >
                                                mdi-text-box-search
                                            </v-icon>
                                            <v-icon v-else class="mr-1">
                                                mdi-text-box-search-outline
                                            </v-icon>
                                        </v-list-item-icon>
                                        <v-list-item-title
                                            v-if="!item.engineering"
                                        >
                                            Set Engineering Process
                                        </v-list-item-title>
                                        <v-list-item-title v-else>
                                            Unset Engineering Process
                                        </v-list-item-title>
                                    </v-list-item>
                                    <v-list-item
                                        v-if="
                                            item.engineering == true &&
                                                user.permissions.includes(
                                                    'editEngineeringDueDate'
                                                )
                                        "
                                        @click.stop="openEngDueDateDialog(item)"
                                    >
                                        <v-list-item-icon>
                                            <v-icon class="mr-1"
                                                >mdi-calendar</v-icon
                                            >
                                        </v-list-item-icon>
                                        <v-list-item-title>
                                            Edit Engineering Due Date
                                        </v-list-item-title>
                                    </v-list-item>
                                </v-list>
                            </v-menu>
                        </div>
                    </template>
                </v-data-table>

                <!-- COST -->
                <v-dialog
                    :retain-focus="false"
                    :fullscreen="$vuetify.breakpoint.mobile"
                    v-model="dialogCost"
                    max-width="1200px"
                    persistent
                >
                    <NewQuoteCostForm
                        v-if="dialogCost"
                        title="Item Details"
                        :originalCost="originalCost"
                        :cost="editCost"
                        :settings="settings"
                        :quote="selectedQuote"
                        @closeDialog="closeCost"
                        :loading="loading"
                        :readOnly="readOnly"
                        :currency="selectedQuote.currency"
                        :exchange="selectedQuote.exchange"
                        :costExchanges="selectedQuote.costExchanges"
                        @updateCosts="updateCosts"
                    />
                </v-dialog>

                <!--QUOTE-->
                <v-dialog
                    :retain-focus="false"
                    v-model="dialogQuote"
                    fullscreen
                    ref="dialogQuote"
                >
                    <v-card v-if="dialogQuote" class="pl-5 ">
                        <v-card-title class="ml-n4">
                            <v-btn icon @click="closeQuote">
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                            {{ selectedQuote.name }}
                        </v-card-title>
                        <v-card-text
                            class="px-0"
                            :style="`height:${this.heightPopUp}px`"
                        >
                            <Quote
                                :id="selectedQuote.id"
                                :quote="selectedQuote"
                                :settings="settings"
                                :history="true"
                            />
                        </v-card-text>
                    </v-card>
                </v-dialog>
                <!--Send to engineering-->
                <v-dialog
                    v-model="engineeringDialog"
                    persistent
                    max-width="400px"
                    :retain-focus="false"
                >
                    <v-card :loading="loading" v-if="engineeringDialog">
                        <v-card-title
                            v-if="engineeringDialog && !engineering"
                            class="text-h5"
                        >
                            <v-btn
                                small
                                icon
                                @click="closeEngineeringDialog"
                                color="primary"
                                class="mr-2"
                            >
                                <v-icon>
                                    mdi-close
                                </v-icon>
                            </v-btn>
                            Send To Engineering</v-card-title
                        >
                        <v-card-title v-else class="text-h5">
                            <v-btn
                                small
                                icon
                                @click="closeEngineeringDialog"
                                color="primary"
                                class="mr-2"
                            >
                                <v-icon>
                                    mdi-close
                                </v-icon>
                            </v-btn>
                            Return From Engineering</v-card-title
                        >

                        <v-card-text
                            v-if="engineeringDialog && !engineering"
                            class="pt-4"
                        >
                            <v-form>
                                <v-row no-gutters>
                                    <v-col cols="12" class="pt-4">
                                        <v-menu
                                            ref="menu"
                                            :close-on-content-click="true"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="auto"
                                        >
                                            <template
                                                v-slot:activator="{ on, attrs }"
                                            >
                                                <v-combobox
                                                    class="py-0 my-0"
                                                    v-model="engineeringDate"
                                                    color="primary"
                                                    label="Eng Due Date"
                                                    readonly
                                                    flat
                                                    hide-details
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    prepend-icon="mdi-calendar-blank-outline"
                                                    :rules="[rules.required]"
                                                ></v-combobox>
                                            </template>
                                            <v-date-picker
                                                v-model="engineeringDate"
                                                scrollable
                                                :min="minDate"
                                            >
                                            </v-date-picker>
                                        </v-menu>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-card-text>
                        <v-card-text v-else>
                            Are you sure you want to return from engineering?
                        </v-card-text>

                        <v-card-actions>
                            <v-spacer />
                            <v-btn
                                v-if="!engineering"
                                :disabled="!engineeringDate"
                                @click="setEngineering(selectedCost)"
                                color="primary"
                                rounded
                                :loading="loading"
                            >
                                SEND
                            </v-btn>
                            <v-btn
                                v-else
                                @click="unSetEngineering(selectedCost)"
                                color="primary"
                                rounded
                                :loading="loading"
                                :disabled="!engineeringDate && !engineering"
                            >
                                RETURN
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>

                <!--Edit engineering due Date-->
                <v-dialog
                    v-model="engineeringDateDialog"
                    :retain-focus="false"
                    persistent
                    max-width="400"
                >
                    <v-card :loading="loading" v-if="engineeringDateDialog">
                        <v-card-title class="text-h5">
                            <v-btn
                                small
                                icon
                                @click="closeEngDueDateDialog"
                                color="primary"
                                class="mr-2"
                            >
                                <v-icon>
                                    mdi-close
                                </v-icon>
                            </v-btn>
                            <h3>
                                Change Eng Due Date
                            </h3></v-card-title
                        >
                        <v-divider class="ml-4 mr-5" />
                        <v-card-text class="pt-4">
                            <v-form>
                                <v-row no-gutters>
                                    <v-col cols="12" class="pt-4">
                                        <v-menu
                                            ref="menu"
                                            :close-on-content-click="true"
                                            :nudge-right="40"
                                            transition="scale-transition"
                                            offset-y
                                            min-width="auto"
                                        >
                                            <template
                                                v-slot:activator="{ on, attrs }"
                                            >
                                                <v-combobox
                                                    class="py-0 my-0"
                                                    v-model="engineeringDate"
                                                    color="primary"
                                                    label="Eng Due Date"
                                                    readonly
                                                    flat
                                                    hide-details
                                                    v-bind="attrs"
                                                    v-on="on"
                                                    prepend-icon="mdi-calendar-blank-outline"
                                                    :rules="[rules.required]"
                                                ></v-combobox>
                                            </template>
                                            <v-date-picker
                                                v-model="engineeringDate"
                                                scrollable
                                                :min="minDate"
                                            >
                                            </v-date-picker>
                                        </v-menu>
                                    </v-col>
                                </v-row>
                            </v-form>
                        </v-card-text>
                        <v-spacer></v-spacer>
                        <v-card-actions class="pb-4">
                            <v-spacer></v-spacer>
                            <v-btn
                                @click="changeEngDueDate"
                                color="primary"
                                :loading="loading"
                                rounded
                                :disabled="!engineeringDate"
                            >
                                CHANGE
                            </v-btn>
                        </v-card-actions>
                    </v-card>
                </v-dialog>
            </v-col>
        </v-row>
    </div>
</template>

<script>
import { mapMutations } from 'vuex'
import moment from 'moment'
import API from '@/services/api'
import _ from 'lodash'
export default {
    name: 'Estimating',
    components: {
        Quote: () => import('@/views/Quotes/Quote.vue'),
        NewQuoteCostForm: () =>
            import('@/components/Quotes/NewQuoteCostForm.vue'),
    },
    data() {
        return {
            height: 0,
            headers: [
                {
                    text: 'Quote',
                    value: 'quote.name',
                    align: 'center',
                    sortable: false,
                    width: 95,
                },
                {
                    text: 'Scopes',
                    value: 'reference',
                    align: 'center',
                    sortable: false,
                    width: 100,
                },
                {
                    text: 'Scope Type',
                    value: 'category',
                    align: 'center',
                    width: 125,
                    sortable: false,
                },
                {
                    text: 'Invitation Date',
                    value: 'quote.invitationDate',
                    align: 'center',
                    width: 105,
                    sortable: false,
                },
                {
                    text: 'Due Date',
                    value: 'quote.requestDate',
                    align: 'center',
                    width: 110,
                    sortable: false,
                },
                {
                    text: 'Promise Date',
                    value: 'quote.promiseDate',
                    align: 'center',
                    width: 150,
                    sortable: false,
                },
                {
                    text: 'Client',
                    value: 'quote.clientId',
                    align: 'center',
                    width: 100,
                    sortable: false,
                },
                {
                    text: 'Users',
                    value: 'quote.userId',
                    align: 'center',
                    width: 120,
                    sortable: false,
                },
                {
                    text: 'Status',
                    value: 'quote.status',
                    align: 'center',
                    sortable: false,
                    width: 100,
                },
                {
                    text: 'Eng Due Date',
                    value: 'engineeringDate',
                    align: 'center',
                    sortable: false,
                    width: 120,
                },
                {
                    text: 'ACTIONS',
                    value: 'actions',
                    sortable: false,
                    width: 110,
                    align: 'center',
                },
            ],
            insights: [
                {
                    color: 'primary',
                    icon: 'mdi-file-document-plus',
                    iconColor: '#1A5276',
                    title: 'Bidding',
                },
                {
                    color: 'green',
                    icon: 'mdi-text-box-search',
                    iconColor: '#1A5276',
                    title: 'Engineering',
                },
                {
                    color: 'orange',
                    icon: 'mdi-file-document-arrow-right',
                    iconColor: '#1A5276',
                    title: 'Approval',
                },
                {
                    color: 'warning',
                    icon: 'mdi-file-document-refresh',
                    iconColor: '#1A5276',
                    title: 'Follow Up',
                },
            ],
            loading: false,
            dialogQuote: false,
            quotes: [],
            loadFollowUp: true,
            selectedQuote: {},
            costs: [],
            quotesToFilter: '',
            referenceToFilter: '',
            categoryToFilter: '',
            promiseDateToFilter: '',
            invitationDateToFilter: '',
            engineeringDateToFilter: '',
            clientToFilter: '',
            accountManagerToFilter: '',
            usersToFilter: [],
            estimatorToFilter: '',
            statusToFilter: '',
            requestDateToFilter: '',
            dialogCost: false,
            originalCost: {},
            editCost: {},
            engineeringDialog: false,
            selectedCost: {},
            settings: [],
            engineering: false,
            startDate: '',
            endDate: '',
            search: null,
            users: [],
            quotesArray: [],
            quotesUsersId: [],
            quotesUsers: [],
            minDate: '',
            rules: {
                required: v => !!v || 'Required',
            },
            engineeringDate: '',
            engineeringDateDialog: false,
            user: {},
            readOnly: true,
        }
    },
    updated() {
        this.onResize()
    },
    computed: {
        filters() {
            let conditions = []

            if (this.quotesToFilter) {
                conditions.push(this.filterForQuotes)
            }
            if (this.referenceToFilter) {
                conditions.push(this.filterForReference)
            }
            if (this.categoryToFilter) {
                conditions.push(this.filterForCategory)
            }
            if (this.promiseDateToFilter) {
                conditions.push(this.filterForPromiseDate)
            }
            if (this.invitationDateToFilter) {
                conditions.push(this.filterForInvitationDate)
            }
            if (this.engineeringDateToFilter) {
                conditions.push(this.filterForEngineeringDate)
            }
            if (this.clientToFilter) {
                conditions.push(this.filterForClient)
            }
            if (this.usersToFilter) {
                conditions.push(this.filterForUsers)
            }
            if (this.estimatorToFilter) {
                conditions.push(this.filterForEstimator)
            }
            if (this.statusToFilter) {
                conditions.push(this.filterForStatus)
            }
            if (this.requestDateToFilter) {
                conditions.push(this.filterForRequestDate)
            }
            if (conditions.length > 0) {
                return this.costs.filter(quote => {
                    return conditions.every(condition => {
                        return condition(quote)
                    })
                })
            }
            this.sort()
            return this.costs
        },
    },
    async mounted() {
        try {
            this.loading = true
            const {
                data: { users },
            } = await API.getLiteUsers()
            this.users = users
            const {
                data: { clients },
            } = await API.getClients()
            this.clients = clients
            const {
                data: { settings },
            } = await API.getSettings()
            this.settings = settings
            const {
                data: { user },
            } = await API.getMyInfo()
            this.user = user

            await this.loadData('OPEN,APPROVAL')
            this.minDate = new Date().toISOString()
        } catch (error) {
            this.setErrorItems({
                source: this.$options.name,
                message: error.message,
            })
        } finally {
            this.loading = false
        }
    },
    methods: {
        ...mapMutations(['setErrorItems']),
        filterForQuotes(item) {
            if (item && item.quoteId && this.quotesToFilter.length > 0) {
                return this.quotesToFilter.some(
                    quote => quote.id == item.quoteId
                )
            } else {
                return true
            }
        },
        filterForReference(item) {
            if (item.reference) {
                return item.reference
                    .toLowerCase()
                    .includes(this.referenceToFilter.toLowerCase())
            } else {
                return false
            }
        },
        filterForCategory(item) {
            if (item.category) {
                return item.category
                    .toLowerCase()
                    .includes(this.categoryToFilter.toLowerCase())
            } else {
                return false
            }
        },
        filterForPromiseDate(item) {
            if (item.quote.promiseDate && item.quote.promiseDate._seconds) {
                const date = this.formatDate(
                    item.quote.promiseDate._seconds
                        ? item.quote.promiseDate._seconds
                        : item.quote.promiseDate.seconds
                        ? item.quote.promiseDate.seconds
                        : 0
                )
                return date.includes(this.promiseDateToFilter)
            } else {
                return false
            }
        },
        filterForInvitationDate(item) {
            if (
                item.quote.invitationDate &&
                item.quote.invitationDate._seconds
            ) {
                const date = this.formatDate(
                    item.quote.invitationDate._seconds
                        ? item.quote.invitationDate._seconds
                        : item.quote.invitationDate.seconds
                        ? item.quote.invitationDate.seconds
                        : 0
                )
                return date.includes(this.invitationDateToFilter)
            } else {
                return false
            }
        },
        filterForClient(item) {
            if (item.quote.clientId) {
                return this.getClientName(item.quote.clientId)
                    .toLowerCase()
                    .includes(this.clientToFilter.toLowerCase())
            } else {
                return false
            }
        },
        filterForAccountManager(item) {
            if (item.quote.userId) {
                return this.getUserName(item.quote.userId)
                    .toLowerCase()
                    .includes(this.accountManagerToFilter.toLowerCase())
            } else {
                return false
            }
        },
        filterForUsers(item) {
            if (item && item.quote && this.usersToFilter.length > 0) {
                let validation = this.usersToFilter.every(field =>
                    [item.quote.userId, item.quote.estimatorId].includes(field)
                )
                return validation
            } else {
                return true
            }
        },
        filterForEstimator(item) {
            if (item.quote.estimatorId) {
                return this.getUserName(item.quote.estimatorId)
                    .toLowerCase()
                    .includes(this.estimatorToFilter.toLowerCase())
            } else {
                return false
            }
        },
        filterForStatus(item) {
            if (item.engineering) {
                return (
                    item.engineering &&
                    'engineering'
                        .toLowerCase()
                        .includes(this.statusToFilter.toLowerCase())
                )
            } else if (item.quote.status) {
                return this.setStatus(item.quote.status)
                    .toLowerCase()
                    .includes(this.statusToFilter.toLowerCase())
            } else {
                return false
            }
        },
        filterForRequestDate(item) {
            if (item.quote.requestDate && item.quote.requestDate._seconds) {
                const date = this.formatDate(
                    item.quote.requestDate._seconds
                        ? item.quote.requestDate._seconds
                        : item.quote.requestDate.seconds
                        ? item.quote.requestDate.seconds
                        : 0
                )
                return date.includes(this.requestDateToFilter)
            } else {
                return false
            }
        },
        filterForEngineeringDate(item) {
            if (item.engineeringDate && item.engineeringDate._seconds) {
                const date = this.formatDate(
                    item.engineeringDate._seconds
                        ? item.engineeringDate._seconds
                        : item.engineeringDate.seconds
                        ? item.engineeringDate.seconds
                        : 0
                )
                return date.includes(this.engineeringDateToFilter)
            } else {
                return false
            }
        },
        setStatus(status) {
            if (status == 'OPEN') {
                return 'Bidding'
            }
            if (status == 'SENT') {
                return 'Follow Up'
            }
            if (status == 'APPROVAL') {
                return 'Approved'
            }
        },

        async loadData(status) {
            try {
                this.loading = true
                this.menu = false

                //set initial dates
                this.endDate = new Date()
                this.endDate = this.endDate.toISOString().split('T')[0]

                let {
                    data: { quotes },
                } = await API.getQuotesEstimatingPlan(status)
                quotes.forEach(quote => {
                    const indexExchanges = this.settings.findIndex(
                        u => u.name === 'Exchange'
                    )
                    const { exchanges, globalCurrency } = this.settings[
                        indexExchanges
                    ]
                    quote.costExchanges = {
                        exchanges,
                        globalCurrency,
                    }
                    quote.userName = quote.userId
                        ? this.getUserName(quote.userId)
                        : ''
                    quote.estimatorName = quote.estimatorId
                        ? this.getUserName(quote.estimatorId)
                        : ''
                    quote.createdOn = quote.createdOn ? quote.createdOn : null
                })

                quotes.sort(
                    (a, b) => a.createdOn._seconds - b.createdOn._seconds
                )
                let index = quotes.findIndex(q => q.createdOn)
                this.startDate = this.formatDate(
                    quotes[index].createdOn._seconds ||
                        quotes[index].createdOn.seconds
                )
                let {
                    data: { costs },
                } = await API.getCostsBigQ(this.startDate, this.endDate)
                costs.forEach(cost => {
                    cost.quoteId = cost.document_name.split('/')[8]
                    cost.quote = quotes.find(quote => quote.id == cost.quoteId)

                    if (cost.quote) {
                        cost.quote.attached = cost.quote.files
                            ? cost.quote.files.filter(x => x.attach == true)
                            : []
                        cost.quote.docs = cost.quote.files
                            ? cost.quote.files.filter(x => x.attach == false)
                            : []
                        cost.quote.lastUpdate = cost.quote.updatedOn
                        cost.costExchanges = cost.quote.costExchanges
                        cost.quote.clientName = cost.quote.clientId
                            ? this.getClientName(cost.clientId)
                            : ''
                        cost.createdOn = cost.createdOn
                            ? this.formatDate(cost.createdOn._seconds)
                            : null
                    }
                })
                costs = costs.filter(cost => cost.quote !== undefined)
                costs.sort(
                    (a, b) =>
                        b.quote.updatedOn._seconds - a.quote.updatedOn._seconds
                )
                //Only leave quotes with costs
                quotes.forEach(quote => {
                    let exist = this.costs.find(
                        cost => cost.quoteId == quote.id
                    )
                    if (exist) {
                        this.quotesArray.push({
                            name: quote.name,
                            id: quote.id,
                        })
                        if (quote.estimatorId) {
                            this.quotesUsersId.push(quote.estimatorId)
                        }
                        if (quote.userId) {
                            this.quotesUsersId.push(quote.userId)
                        }
                    } else {
                        // quotes = quotes.filter(q => q.id != quote.id)
                    }
                })
                this.quotesUsersId = [...new Set(this.quotesUsersId)]
                this.quotesUsersId.forEach(user =>
                    this.quotesUsers.push({
                        id: user,
                        name: this.getUserName(user),
                    })
                )
                if (this.quotes.length > 0)
                    this.quotes = this.quotes.concat(quotes)
                else this.quotes = quotes

                if (this.costs.length > 0) this.costs = this.costs.concat(costs)
                else this.costs = costs
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        sort() {
            this.costs.sort(
                (a, b) =>
                    b.quote.updatedOn._seconds - a.quote.updatedOn._seconds
            )
        },
        sort2() {
            this.quotes.sort(
                (a, b) => a.createdOn._seconds - b.createdOn._seconds
            )
        },
        sort3() {
            this.costs.sort(
                (a, b) => b.createdOn._seconds - a.createdOn._seconds
            )
        },
        openEngDueDateDialog(item) {
            this.selectedCost = _.cloneDeep(item)
            this.engineeringDateDialog = true
        },
        closeEngDueDateDialog() {
            this.selectedCost = {}
            this.engineeringDate = null
            this.engineeringDateDialog = false
        },
        openCost(item) {
            this.originalCost = _.cloneDeep(item)
            this.editCost = _.cloneDeep(item)
            this.selectedQuote = _.cloneDeep(item.quote)
            this.dialogCost = true
        },
        closeCost() {
            this.dialogCost = false
            this.selectedQuote = {}
            this.originalCost = {}
            this.editCost = {}
        },
        openQuote(item) {
            this.selectedQuote = _.cloneDeep(item.quote)
            this.dialogQuote = true
        },
        closeQuote() {
            this.selectedQuote = {}
            this.dialogQuote = false
        },
        openEngineeringDialog(item) {
            this.selectedCost = _.cloneDeep(item)
            this.engineering = this.selectedCost.engineering
            this.engineeringDialog = true
        },
        closeEngineeringDialog() {
            this.selectedCost = {}
            this.engineering = false
            this.engineeringDate = null
            this.engineeringDialog = false
        },
        async setEngineering() {
            try {
                this.selectedCost.engineering = !this.selectedCost.engineering
                this.selectedCost.engineeringDate = this.engineeringDate
                this.loading = true
                const cost = await API.sendCostToEngineering(
                    this.selectedCost.quoteId,
                    this.selectedCost.document_id,
                    {
                        engineering: this.selectedCost.engineering,
                        engineeringDate: this.selectedCost.engineeringDate,
                    }
                )
                this.updateCost(cost)
                this.closeEngineeringDialog()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async unSetEngineering() {
            try {
                this.selectedCost.engineering = !this.selectedCost.engineering
                this.selectedCost.engineeringDate = this.engineeringDate
                this.loading = true
                const cost = await API.sendCostToEngineering(
                    this.selectedCost.quoteId,
                    this.selectedCost.document_id,
                    {
                        engineering: this.selectedCost.engineering,
                    }
                )
                this.updateCost(cost)
                this.closeEngineeringDialog()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        async changeEngDueDate() {
            try {
                this.selectedCost.engineeringDate = this.engineeringDate
                this.loading = true
                const cost = await API.sendCostToEngineering(
                    this.selectedCost.quoteId,
                    this.selectedCost.document_id,
                    {
                        engineeringDate: this.selectedCost.engineeringDate,
                    }
                )
                this.updateCost(cost)
                this.closeEngDueDateDialog()
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.loading = false
            }
        },
        formatDate(seconds = undefined) {
            return `${moment.unix(seconds).format('YYYY-MM-DD')}`
        },
        getUserName(id) {
            if (this.users.length > 0 && id) {
                return this.users.find(user => user.id == id)?.name
            } else {
                return ''
            }
        },
        getClientName(id) {
            if (this.clients.length > 0) {
                return this.clients.find(client => client.id == id)?.name
            } else {
                return ''
            }
        },
        onResize() {
            this.height = window.innerHeight - 112
            this.heightPopUp = window.innerHeight - 62
        },
        updateCost(cost) {
            if (cost.engineeringDate) {
                cost.engineeringDate = cost.engineeringDate.substring(0, 10)
            }

            let index = this.costs.findIndex(c => c.document_id === cost.id)
            if (index !== -1) {
                this.costs.splice(index, 1, {
                    ...this.costs[index],
                    ...cost,
                })
            }
        },
        updateCosts: async function(item) {
            try {
                this.loading = true
                this.error = false
                this.errorMsg = null
                item.id = item.document_id
                delete item.createdOn
                delete item.updatedOn
                delete item.engineeringExitDate
                delete item.engineeringIngressDate
                delete item.document_name
                delete item.document_id
                delete item.timestamp
                delete item.event_id
                delete item.operation
                delete item.companyId
                delete item.costExchanges
                delete item.index
                delete item.quote
                const cost = await API.updateCosts(item.quoteId, {
                    ...item,
                    ...(item.engineeringDate &&
                    typeof item.engineeringDate == 'object'
                        ? {
                              engineeringDate: moment
                                  .unix(
                                      item.engineeringDate._seconds ||
                                          item.engineeringDate.seconds
                                  )
                                  .format('YYYY-MM-DD'),
                          }
                        : {}),
                })

                if (cost && cost.engineeringDate) {
                    cost.engineeringDate = cost.engineeringDate.substring(0, 10)
                }
                const index = this.costs.findIndex(
                    c => cost.id == c.document_id
                )
                if (index >= 0) {
                    this.costs.splice(index, 1, {
                        ...this.costs[index],
                        ...cost,
                    })
                }
            } catch (error) {
                this.setErrorItems({
                    source: this.$options.name,
                    message: error.message,
                })
            } finally {
                this.closeCost()
                this.loading = false
            }
        },
    },
}
</script>

<style>
.v-data-table {
    border: 1px solid #eeeeee;
}
thead th {
    background-color: #eeeeee !important;
}
/* .top {
    background-color: #eeeeee;
} */
#virtual-scroll-table {
    width: 100%;
    overflow: auto;
}
.clickable-row {
    cursor: pointer;
}
</style>
